<template>
  <nav class="border-b border-gray-lighter flex items-center">
    <ul class="-mb-px flex">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.value"
        @click="switchTab(tab.value)"
        class="whitespace-no-wrap py-4 px-1 border-b-2"
        :class="[
          { 'ml-4': index > 0 },
          value === tab.value
            ? 'border-brand-darker text-brand'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer'
        ]"
      >
        <a class="whitespace-no-wrap font-medium select-none">
          <span>{{ tab.label }}</span>

          <slot name="badge" :count="tab.count" :tabValue="tab.value">
            <UtilBadgeCount
              v-if="typeof tab.count !== 'undefined'"
              :count="tab.count"
              :showIfZero="showBadgeIfZero"
              :unread="false"
              gray
              class="ml-1 sm:ml-2"
            />
          </slot>
        </a>
      </li>
    </ul>

    <slot name="action"></slot>
  </nav>
</template>

<script>
import UtilBadgeCount from '@/components/utils/UtilBadgeCount'

export default {
  name: 'UtilTabs',
  components: {
    UtilBadgeCount
  },
  props: {
    value: [String, Number],
    tabs: {
      type: Array,
      required: true
    },
    targetClass: String,
    showBadgeIfZero: Boolean
  },
  methods: {
    switchTab(tab) {
      this.$emit('input', tab)
      this.$emit('switchTab', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  @apply py-2 px-4 inline-block select-none;
  @apply text-gray-darker font-semibold cursor-pointer;

  &:hover {
    @apply text-gray-darker;
  }
}

#app.xs .tab {
  @apply px-2 flex flex-no-wrap items-center;
}

.tab.active {
  @apply text-primary border-l border-t border-r border-gray rounded-t -mb-px;
}

.tab-text {
  line-height: 25px;
}
</style>
