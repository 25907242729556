<template>
  <div>
    <UtilTabs :tabs="compTabs" :value="$route.path" @input="path => $router.push(path).catch(() => {})" />

    <div class="mt-8">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import UtilTabs from '@/components/utils/UtilTabs'

export default {
  name: 'Insights',
  components: {
    UtilTabs
  },
  computed: {
    compTabs() {
      return [
        {
          label: 'KPIs', // columns (what about breakdown endpoint)
          value: '/insights'
        },
        {
          label: 'Users', // cohorts and finder (with modal?)
          value: '/insights/users'
        },
        {
          label: 'Listings', // matchmaker help
          value: '/insights/listings'
        },
        {
          label: 'Subscribers', // subscriber insights
          value: '/insights/subscribers'
        },
        {
          label: 'Goals', // front end
          value: '/insights/goals'
        }
      ]
    }
  }
}
</script>
