<template>
  <span v-if="count || showIfZero" class="badge" :class="{ unread, gray, white, primary }">
    {{ count }}
  </span>
</template>

<script>
export default {
  name: 'UtilBadgeCount',
  props: {
    count: [Number, String],
    showIfZero: Boolean,
    unread: Boolean,
    gray: Boolean,
    white: Boolean,
    primary: Boolean
  }
}
</script>

<style lang="scss" scoped>
.badge {
  @apply text-center text-xs rounded-full font-medium inline-block;
  display: inline-block;
  vertical-align: middle;
  padding: 0.2rem;
  min-width: 30px;
}

#app.xs .badge {
  padding: 0 0.8rem;
  line-height: 18px;
  height: 18px;
}

.badge.gray {
  @apply bg-gray-lighter;
}

.badge.white {
  @apply bg-white text-gray-darker;
}

.badge.primary {
  @apply bg-primary text-white;
}

.unread {
  @apply bg-red-500 text-white;
}
</style>
